<template lang="pug">
v-container(light)
    v-row
        v-col
            p.text-h5.black--text SAT Download
    v-row
        v-col
            v-btn.white--text(download,  color='green', @click="downloadFile") {{fileVersion}}

    v-row
        v-col
            v-list(dense, light)
                v-list-item-group
                    v-list-item
                        v-list-item-content
                            v-list-item-title Click the Download button above to download the latest version of SAT
                            v-list-item-subtitle Download name: SATINSTALL.EXE
                    v-list-item
                        v-list-item-content
                            v-list-item-title Run installer as an adminsitrator
                            v-list-item-subtitle Alternatively, run the installer and provide credentials when prompted
                    v-list-item
                        v-list-item-content
                            v-list-item-title Run Sales Automation Tool
                            v-list-item-subtitle First-time logins will require an offline password to be created after Office365 login
                    v-list-item
                        v-list-item-content
                            v-list-item-title Ensure server indicator is green (top right-hand side of program window)
                            v-list-item-subtitle If the indiciator is red, SAT cannot connect to the Central Server; contact IT for support

</template>

<script>
import _ from 'lodash';


export default {
    data (){
        return {
            dti,
            busy: false,
            download: [],
            fileVersion: '',
            fileURL: '',
        };
    },
    
    methods: {
        getLatest () {
            this.axios
                .get(`/satapi/updates/latest.yml`)
                .then((response) => {
                    let data = response.data;
                    let re = new RegExp('url: (Sales-Automation-Tool-Setup-.*?.exe)');
                    let results = data.match(re);
                    this.fileVersion = results[1];
                    this.fileURL = (`${this.axios.defaults.baseURL}satapi/updates/${this.fileVersion}`);
                });
        },

        downloadFile () {
            window.open(this.fileURL);
        }

    },
    mounted (){
        window.satdownloadvm = this;
        this.getLatest();
    }
};
</script>
